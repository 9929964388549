<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="vertical">
          <a-row :gutter="48">
            <!-- <a-col
              :md="6"
              :sm="24"
            >
              <a-form-item label="类型">
                <a-select
                  v-model="queryParam.appType"
                  placeholder="请选择"
                  allow-clear
                >
                  <a-select-option
                    v-for="(item, key) in [{ name: '安卓', id: 0 }, { name: 'IOS', id: 1 }, { name: '固件', id: 2 }]"
                    :key="key"
                    :value="item.id"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col
              :md="6"
              :sm="24"
            >
              <a-form-item label="版本号名称">
                <a-input
                  v-model="queryParam.versionName"
                  placeholder="请输入"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col
              :md="6"
              :sm="24"
            >
              <a-form-item label="版本号">
                <a-input
                  v-model="queryParam.versionCode"
                  placeholder="请输入"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col
              :md="6"
              :sm="24"
            >
              <span class="table-page-search-submitButtons">
                <a-button
                  type="primary"
                  @click="handleQuery"
                ><a-icon type="search" />查询</a-button>
                <a-button
                  style="margin-left: 8px;margin-top:28px;"
                  @click="resetQuery"
                ><a-icon type="redo" />重置</a-button>
                <a-button
                  type="primary"
                  style="margin-left: 8px;margin-top:28px;"
                  @click="$refs.createForm.handleAdd()"
                >
                  <a-icon type="plus" />新增
                </a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">

        <!-- <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['valve:project:edit']">
          <a-icon type="edit" />修改
        </a-button> -->
        <!-- <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
        >
          <a-icon type="delete" />删除
        </a-button> -->
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span
          slot="appType"
          slot-scope="text, record"
        >
          <span v-if="record.appType == 0">安卓</span>
          <span v-else-if="record.appType == 1">IOS</span>
          <span v-else-if="record.appType == 2">固件</span>
        </span>
        <span
          slot="downloadUrl"
          slot-scope="text, record"
        >
          <a-popover title="下载地址">
            <template slot="content">{{ record.downloadUrl }}</template>
            <a type="primary">查看</a>
          </a-popover>
        </span>
        <span
          slot="createTime"
          slot-scope="text, record"
        >
          {{ parseTime(record.createTime) }}
        </span>
        <span
          slot="operation"
          slot-scope="text, record"
        >
          <!-- <a-divider type="vertical" v-hasPermi="['valve:project:edit']" /> -->
          <!-- <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['valve:project:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['valve:project:remove']" /> -->
          <a @click="handleDelete(record)">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageappVersion, delappVersion } from '@/api/system/version'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Project',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        appType: undefined,
        versionName: null,
        versionCode: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '类型',
          dataIndex: 'appType',
          scopedSlots: { customRender: 'appType' },
          ellipsis: true,
          width: 80,
          align: 'center'
        },
        {
          title: '版本号名称',
          dataIndex: 'versionName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '版本号',
          dataIndex: 'versionCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '更新说明',
          dataIndex: 'upNotes',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '下载地址',
          dataIndex: 'downloadUrl',
          scopedSlots: { customRender: 'downloadUrl' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: 160
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 80,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询项目信息列表 */
    getList () {
      this.loading = true
      pageappVersion(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        appType: undefined,
        versionName: null,
        versionCode: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delappVersion(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () { }
      })
    }
  }
}
</script>
